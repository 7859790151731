// extracted by mini-css-extract-plugin
export var root = "selection-module--root--81432";
export var bannerContainer = "selection-module--bannerContainer--18bd7";
export var subtitleStyle = "selection-module--subtitleStyle--71906";
export var metaContainer = "selection-module--metaContainer--f91cc";
export var controllerContainer = "selection-module--controllerContainer--529e5";
export var iconContainer = "selection-module--iconContainer--a0ee8";
export var sortContainer = "selection-module--sortContainer--5e5ae";
export var itemCount = "selection-module--itemCount--2bf35";
export var chipsContainer = "selection-module--chipsContainer--d9954";
export var productContainer = "selection-module--productContainer--15468";
export var loadMoreContainer = "selection-module--loadMoreContainer--017ad";
export var mobileItemCount = "selection-module--mobileItemCount--f64ae";
export var breadcrumbContainer = "selection-module--breadcrumbContainer--8f8f8";